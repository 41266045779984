<template>
  <div
    v-if="parsed"
    v-tippy
    :content="typeName"
    class="d-flex align-items-center"
  >
    <i
      class="far pr-1"
      :class="icon"
    />
    <div v-if="details">
      <div
        class="small ml-2"
        style="width: 110px"
      >
        <div>
          {{ parsed.hour }}
        </div>
        <div v-if="parsed.dayOffset != 0">
          <span v-if="parsed.dayOffset < 0">{{ parsed.dayOffset }} {{ $t('shop.timeReference.daysBefore') }}</span>
          <span v-if="parsed.dayOffset > 0">{{ parsed.dayOffset }} {{ $t('shop.timeReference.daysAfter') }}</span>
        </div>
        <div v-if="parsed.skipWeekend">
          {{ $t('report.time.skipWeekend') }}
        </div>
      </div>
    </div>
    <template v-else>
      <span
        v-if="parsed.dayOffset != 0"
        class="pr-1"
      >
        ({{ parsed.dayOffset >= 0 ? '+' : '' }}{{ parsed.dayOffset }}d)
      </span>
      <span v-if="parsed.hour != '00:00'">{{ parsed.hour }}</span>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: String,
    details: Boolean,
  },
  computed: {
    ...mapGetters('shop', [
      'timeReferenceTypes',
      'parseTimeReference',
    ]),
    typeName() {
      return this.timeReferenceTypes.find((x) => x.value === this.parsed?.type)?.text;
    },
    icon() {
      switch (this.parsed?.type) {
        case 'ListingMonthStart':
          return 'fa-arrow-left-to-line';
        case 'ListingMonthEnd':
          return 'fa-arrow-right-to-line';
        case 'ListingDay':
          return 'fa-calendar-day';
        default:
          return '';
      }
    },
    parsed() {
      return this.parseTimeReference(this.value);
    },
  },
};
</script>
