<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <SearchBox
          v-model="search"
          lazy
          class="mr-3"
        />
      </div>

      <div>
        <button
          class="btn btn-sm btn-primary mr-2"
          @click="$router.push(`/payee/${payeeId}/shop/import`)"
        >
          <i class="fas fa-upload pr-2" />
          {{ $t('menu.import') }}
        </button>

        <button
          class="btn btn-sm btn-primary"
          @click="productModal = true"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('shop.addProduct') }}
        </button>
      </div>
    </div>
    <hr class="mb-1">
    <div>
      <ColumnEdit
        :edit="!!(editedId || editedVariantId)"
      >
        <div
          class="table-header-row d-none d-md-flex w-100"
        >
          <label
            class="mb-0 column-header pl-2"
            style="width: 500px"
          >
            {{ $t('general.name') }}
          </label>
          <label
            class="mb-0 column-header flex-grow-1 pl-2"
          >
            {{ $t('shop.tags') }}
          </label>
        </div>
        <div
          style="height: calc(100vh - 220px); overflow: auto"
        >
          <div
            v-for="p in filteredProducts"
            :key="p.id"
          >
            <div
              :class="{ selected: editedId === p.id }"
              class="d-flex align-items-center"
            >
              <div
                class="table-row"
                @click="$set(expanded, p.id, !expanded[p.id])"
              >
                <div
                  class="cell caret-cell"
                >
                  <i
                    v-if="expanded[p.id]"
                    class="fa-solid fa-caret-down pr-2 text-secondary"
                  />
                  <i
                    v-else
                    class="fa-solid fa-caret-right pr-2 text-secondary"
                  />
                </div>
              </div>
              <div
                class="table-row flex-grow-1"
                @click="toggleEdit(p)"
              >
                <div class="cell name-cell">
                  <i class="fas fa-tag product-icon" />

                  {{ p.name }}
                </div>
              </div>
            </div>

            <div
              v-if="expanded[p.id]"
              class="pl-3"
            >
              <div
                v-for="v in p.variants"
                :key="v.variantId"
                class="table-row align-items-center"
                :class="{ selected: editedVariantId === v.variantId }"
                @click="toggleVariantEdit(v)"
              >
                <div class="cell name-cell">
                  {{ v.name }}
                </div>
                <div class="cell d-flex align-items-center flex-grow-1">
                  <span
                    v-for="t in v.tags"
                    :key="t"
                    class="tag-label"
                  >
                    {{ t }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template #edit>
          <div
            v-if="edited"
            class="p-2 page-content position-relative"
          >
            <div
              class="sticky-top pt-1 px-4 page-content w-100"
              style="max-height: 100vh; overflow: auto; top: 50px; min-height: 100px"
            >
              <CloseButton
                style="position: absolute; right: 8px; top: 0px; z-index: 100"
                @click.native.stop="toggleEdit(edited)"
              />
              <Transition
                name="client-details"
                mode="out-in"
              >
                <ProductDetails
                  v-if="detailsReady"
                  :key="editedId"
                  :product="edited"
                  @removed="removed"
                  @refresh="$emit('refresh')"
                  @add-variant="productVariantModal = true"
                />
              </Transition>
            </div>
          </div>
          <div
            v-else-if="editedVariant"
            class="p-2 page-content position-relative"
          >
            <div
              class="sticky-top pt-1 px-4 page-content w-100"
              style="max-height: 100vh; overflow: auto; top: 50px; min-height: 100px"
            >
              <CloseButton
                style="position: absolute; right: 8px; top: 0px; z-index: 100"
                @click.native.stop="toggleVariantEdit(editedVariant)"
              />
              <Transition
                name="client-details"
                mode="out-in"
              >
                <ProductVariantDetails
                  v-if="detailsReady"
                  :key="editedVariantId"
                  :variant="editedVariant"
                  @removed="removed"
                  @refresh="$emit('refresh')"
                />
              </Transition>
            </div>
          </div>
        </template>
      </ColumnEdit>
    </div>
    <BModal
      v-model="productModal"
      hide-footer
      hide-header
      size="lg"
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="productModal = false"
      />
      <div
        class="font-weight-bold"
        style="font-size: 20px"
      >
        {{ $t('shop.newProduct') }}
      </div>
      <div class="p-3">
        <ProductForm
          :catalog-id="catalogId"
          @close="productModal = false"
          @refresh="$emit('refresh')"
        />
      </div>
    </BModal>
    <BModal
      v-model="productVariantModal"
      hide-footer
      hide-header
      size="lg"
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="productVariantModal = false"
      />
      <div
        class="font-weight-bold"
        style="font-size: 20px"
      >
        {{ $t('shop.newProductVariant') }}
      </div>
      <div class="p-3">
        <ProductVariantForm
          :product-id="editedId"
          :catalog-id="catalogId"
          @close="productVariantModal = false"
          @refresh="$emit('refresh')"
        />
      </div>
    </BModal>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import ProductDetails from '@/components/shop/ProductDetails';
import ProductForm from '@/components/shop/ProductForm';
import ProductVariantDetails from '@/components/shop/ProductVariantDetails';
import ProductVariantForm from '@/components/shop/ProductVariantForm';
import createSearch from '@/utils/search';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    editedId: null,
    editedVariantId: null,
    search: '',
    detailsReady: false,
    productModal: false,
    productVariantModal: false,
    expanded: {},
  }),
  components: {
    ProductDetails,
    ProductVariantDetails,
    ProductVariantForm,
    ProductForm,
    ColumnEdit,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapGetters('shop', ['products']),
    filteredProducts() {
      const search = createSearch(this.search);
      return (this.products || []).filter((x) => search(x.name) || search(x.code));
    },
    edited() {
      return this.products?.find((x) => x.id === this.editedId);
    },
    editedVariant() {
      if (!this.products) return null;
      return this.products
        .flatMap((x) => x.variants)
        .find((x) => x.variantId === this.editedVariantId);
    },
  },
  methods: {
    ...mapActions('shop', ['getProducts']),
    removed() {
      this.editedId = null;
      this.editedVariantId = null;
      this.detailsReady = false;
      this.$emit('refresh');
    },
    toggleEdit(product) {
      clearTimeout(this.timeout);
      this.editedVariantId = null;

      this.detailsReady = false;
      if (this.editedId !== product.id) {
        this.editedId = product.id;
        this.timeout = setTimeout(() => {
          this.detailsReady = true;
        }, 200);
      } else {
        this.detailsReady = false;
        this.editedId = null;
      }
    },
    toggleVariantEdit(variant) {
      this.editedId = null;

      clearTimeout(this.timeout);
      this.detailsReady = false;
      if (this.editedVariantId !== variant.variantId) {
        this.editedVariantId = variant.variantId;
        this.timeout = setTimeout(() => {
          this.detailsReady = true;
        }, 200);
      } else {
        this.detailsReady = false;
        this.editedVariantId = null;
      }
    },
  },
  created() {
    this.$emit('page', 'products');
  },
};
</script>

<style lang="scss" scoped>

  .selected {
    background-color: rgba($blue, 0.1);
  }

  .caret-cell {
    text-align: center;
    width: 30px;
  }

  .name-cell {
    width: 470px;
  }

  .tag-label {
    padding: 2px 10px;
    border: 1px solid #888;
    color: #888;
    font-weight: 500;
    border-radius: 10px;
    font-size: 12px;
  }

  .product-icon {
    margin-right: 5px;
    color: #9a9a9a;
  }

  .code-column {
    max-width: 150px;
    min-width: 150px;
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
</style>
