<template>
  <div v-if="!catalog && pending">
    <Loader />
  </div>
  <div
    v-else-if="!catalog"
    class="text-center font-weight-bold text-secondary"
  >
    Catalog does not exist!
  </div>
  <div
    v-else
    class="d-flex flex-column"
  >
    <div class="flex-grow-1 d-flex">
      <div class="h-100">
        <SubCatalogsSidebar
          class="sidebar"
          :catalog="catalog"
          :selected.sync="selectedNodes"
        />
      </div>
      <ColumnEdit
        :edit="form || !!editedId"
        style="border-left: 1px solid #efefef; min-width: 0;"
        class="flex-grow-1 h-100"
      >
        <div
          class="full-height"
          style="padding-bottom: 20px;"
        >
          <div class="list-header m-2">
            <div>
              <SearchBox v-model="search" />
            </div>
            <button
              class="btn btn-primary btn-sm px-3"
              @click="editedId = null; form = true"
            >
              <i class="fas fa-plus mr-2" />
              <span class="edit-details"> Dodaj ofertę </span>
            </button>
          </div>
          <NodeSelectedRow
            v-for="n in selectedNodeList"
            :key="n.subCatalogId"
            :node="n.node"
            @remove="$delete(selectedNodes, n.subCatalogId)"
          />

          <div class="wide-table-wrapper">
            <div
              class="wide-table"
              :style="{
                'min-width': `${940 + 120*subCatalogsSorted.length}px`
              }"
            >
              <div class="header d-flex">
                <div style="min-width: 300px">
                  {{ $t('general.name') }}
                </div>
                <div style="min-width: 200px">
                  {{ $t('shop.products') }}
                </div>
                <div
                  v-for="s in subCatalogsSorted"
                  :key="s.subCatalogId"
                  class="assignment-column"
                >
                  {{ s.name }}
                </div>
                <div class="time-column">
                  {{ $t('shop.orderFrom') }}
                </div>
                <div class="time-column">
                  {{ $t('shop.orderTo') }}
                </div>
                <div class="time-column">
                  {{ $t('shop.cancelTo') }}
                </div>
                <div style="min-width: 120px; text-align: right;">
                  {{ $t('shop.price') }}
                </div>
              </div>

              <ProductListingRow
                v-for="listing in filteredListings"
                :key="listing.id"
                :listing="listing"
                :selected="listing.id === editedId"
                @click.native="toggle(listing.id)"
              />
            </div>
          </div>
        </div>

        <template #edit>
          <div
            v-if="editedId || form"
            class="page-cart full-height"
          >
            <ProductListingDetails
              :listing="edited"
              @close="form = false; editedId = null"
            />
          </div>
        </template>
      </ColumnEdit>
    </div>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import { accountNumber } from '@/utils/filters';
import createSearch from '@/utils/search';
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductListingDetails from './listing/ProductListingDetails';
import NodeSelectedRow from './NodeSelectedRow';
import ProductListingRow from './ProductListingRow';
import SubCatalogsSidebar from './SubCatalogsSidebar';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    editedId: null,
    form: false,
    pending: false,
    selectedNodes: [],
    listings: [],
    editedSubCatalog: null,
    search: '',
  }),
  components: {
    ColumnEdit,
    NodeSelectedRow,
    ProductListingRow,
    ProductListingDetails,
    SubCatalogsSidebar,
  },
  computed: {
    ...mapGetters([
      'payee',
      'payeeId',
      'groups',
    ]),
    ...mapState({
      catalog: (state) => state.shop.catalogs.catalogDetails,
    }),
    edited() {
      return this.listings.find((x) => x.id === this.editedId);
    },
    subCatalogsSorted() {
      return this.catalog.subCatalogs
        .slice()
        .sort((a, b) => a.ordinal - b.ordinal);
    },
    selectedNodeList() {
      return Object.entries(this.selectedNodes)
        .map(([subCatalogId, nodeId]) => ({
          node: subCatalogId === '#groups'
            ? this.groups.find((x) => x.code === nodeId)
            : this.catalog.subCatalogNodes.find((n) => n.nodeId === nodeId),
          nodeId,
          subCatalogId,
        }));
    },
    filteredListings() {
      const search = createSearch(this.search);

      return this.listings
        .filter((x) => search(x.name))
        .filter((x) =>
          Object.values(this.selectedNodes).every((nodeId) => x.nodeIds.includes(nodeId)));
    },
    bankAccountName() {
      const name = this.payee.bankAccounts
        .find((x) => x.accountNumber.toString() === (this.catalog.accountNumber || '').toString())?.name;

      if (!name) {
        return accountNumber(this.catalog.accountNumber);
      }

      return name;
    },
  },
  methods: {
    ...mapActions('shop', [
      'getShopCatalog',
      'getCatalogListings',
    ]),
    request() {
      this.getCatalogListings({
        params: {
          catalogId: this.catalogId,
        },
      })
        .then(({ data }) => {
          this.listings = data;
        });

      this.getShopCatalog({
        params: {
          catalogId: this.catalogId,
        },
      })
        .finally(() => {
          this.pending = false;
        });
    },
    toggle(listingId) {
      if (this.editedId === listingId) {
        this.editedId = null;
      } else {
        this.editedId = listingId;
      }
    },
  },
  created() {
    this.pending = true;
    this.request();
    this.$emit('page', 'offer');
  },
};
</script>

<style lang="scss" scoped>
.catalog-name {
  font-weight: 500;
  font-size: 17px;
  min-width: 400px;
}

.time-column {
  min-width: 90px;
}

.assignment-column {
  flex-grow: 1;
  min-width: 120px;
  flex-basis: 120px;
}

.wide-table-wrapper {
  overflow: auto;
}

.wide-table {
  min-width: 1100px;
}

.header {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  margin: 5px 10px;
  padding: 5px 15px
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(239, 239, 239)
}

.sidebar {
  height: calc(100vh - 200px);
}

.catalog-type {
  color: #666;
  font-size: 0.7rem;
}

.category {
  font-size: 12px;
  color: #888;

  label {
    font-size: 0.7rem;
  }
}

.catalog-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}

.full-height {
  height: calc(100vh - 200px);
  overflow: auto;
}
</style>
