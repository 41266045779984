<template>
  <Tippy
    trigger="click"
    interactive
    theme="light"
    placement="left"
    max-width="600"
    @show="opened"
  >
    <template #trigger>
      <i class="fas fa-plus secondary-icon" />
    </template>

    <SubCatalogsSidebar
      :catalog="catalogDetails"
      :selected="selected"
      class="text-left"
      select-only
      @update:selected="$emit('update:selected', $event)"
    />
    <!-- <div
      class="row"
      style="width: 600px;"
    >
      <div class="products-dropdown dropdown-scroll col-5">
        <div
          v-for="p in catalogDetails.subCatalogs"
          :key="p.id"
          class="sort-option"
          @click="toggle(p.id)"
        >
          <i
            v-if="selected.includes(p.id)"
            class="fa-regular fa-circle-check pr-1"
          />
          {{ p.name }}
        </div>
      </div>
      <div class="products-dropdown dropdown-scroll col-7">
        <div style="padding-bottom: 5.5px">
          <SearchBox
            ref="search"
            v-model="search"
            :height="30"
            constant-width
          />
        </div>
        <div
          v-for="p in catalogDetails.subCatalogNodes"
          :key="p.nodeId"
        >
          {{ p.name }}
        </div>
      </div>
    </div> -->
  </Tippy>
</template>

<script>
import SubCatalogsSidebar from '@/pages/shop/catalogs/SubCatalogsSidebar';
import createSearch from '@/utils/search';
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Array,
  },
  data: () => ({
    search: '',
  }),
  components: {
    SubCatalogsSidebar,
  },
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    filteredSubCatalogs() {
      const search = createSearch(this.search);
      return this.catalogDetails.subCatalogs
        .filter((x) => search(x.name));
    },
  },
  methods: {
    toggle(id) {
      if (this.selected.includes(id)) {
        this.$emit('update:selected', this.selected.filter((x) => x !== id));
      } else {
        this.$emit('update:selected', [...this.selected, id]);
      }
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/payers/style.scss';

.products-dropdown {
  text-align: left;
  min-width: auto;
  height: 500px;
}
</style>
