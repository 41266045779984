<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div>
        <CalendarDropdown
          :highlighted.sync="days"
          :dates.sync="dates"
          :available-days="[]"
          :day-status="{}"
          all-available
          single-day
          class="mr-3 mb-1"
        />
      </div>
      <!-- @click.stop="downloadReport(sub.id, true)" -->
      <!-- @click.stop="downloadReport(sub.id, false)" -->
      <!-- <div class="d-flex">
        <div>
          <div
            v-tippy
            class="secondary-icon ml-2"
            :class="{ disabled: downloadPending }"
            :content="$t('shop.downloadReport')"
          >
            <Loader
              v-if="downloadPending"
              size="20px"
              style="padding-top: 6px"
            />
            <i
              v-else
              class="fa-solid fa-download"
            />
          </div>
        </div>
        <div
          v-tippy
          class="secondary-icon ml-2"
          :class="{ disabled: sendPending }"
          :content="$t('shop.reports.sendReport')"
        >
          <Loader
            v-if="sendPending"
            size="20px"
            style="padding-top: 6px"
          />
          <i
            v-else
            class="fas fa-paper-plane"
          />
        </div>
      </div> -->
      <button
        class="btn btn-sm btn-primary"
        @click="edited = null; form = true"
      >
        <i class="fas fa-plus pr-1" />
        {{ $t('general.add') }}
      </button>
    </div>
    <hr class="mt-1">
    <div
      v-for="sub in parsedSubscriptions"
      :key="sub.id"
      class="subscription-row d-flex align-items-center mb-2"
      @click="edited = sub; form = true"
    >
      <i
        class="report-type-icon text-secondary pr-3 pl-1"
        :class="sub.reportType.icon"
      />
      <div class="flex-grow-1">
        <div class="d-flex">
          <span class="font-weight-bold flex-grow-1">
            {{ sub.config.name }}
          </span>
          <div class="small">
            <template v-if="sub.config.emailDisabled">
              <span class="fa-stack">
                <i class="far fa-envelope fa-stack-1x" />
                <i class="fas fa-slash fa-stack-1x" />
              </span>
            </template>
            <template v-else>
              <i class="far fa-clock" />
              {{ sub.config.hour }}
              <span v-if="sub.config.dayOffset">
                <i
                  class="far ml-1"
                  :class="sub.config.skipWeekend ? 'fa-calendar-week' : 'fa-calendar'"
                />
                &nbsp;<span v-if="sub.config.dayOffset > 0">+</span>{{ sub.config.dayOffset }}
              </span>
            </template>
          </div>
        </div>
        <div class="subscription-details small text-secondary">
          <div class="d-flex align-items-center mb-1">
            <div class="detail-icon">
              <i class="far fa-users" />
            </div>
            <div>
              {{ sub.groups.join(', ') }}
            </div>
          </div>
          <div
            v-if="!sub.config.emailDisabled"
            class="d-flex align-items-center"
          >
            <div class="detail-icon">
              <i class="far fa-envelope" />
            </div>
            <div>
              {{ sub.config.emails.join(', ') }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="border-left ml-2"
        @click.stop="downloadReport(sub.id, false)"
      >
        <div
          v-tippy
          class="secondary-icon ml-2"
          :class="{ disabled: pending[sub.id] }"
          :content="$t('shop.downloadReport')"
        >
          <Loader
            v-if="pending[sub.id] === 'download'"
            size="20px"
            style="padding-top: 6px"
          />
          <i
            v-else
            class="fa-solid fa-download"
          />
        </div>
      </div>
      <div
        @click.stop="downloadReport(sub.id, true)"
      >
        <div
          v-tippy
          class="secondary-icon ml-2"
          :class="{ disabled: pending[sub.id] }"
          :content="$t('shop.reports.sendReport')"
        >
          <Loader
            v-if="pending[sub.id] === 'email'"
            size="20px"
            style="padding-top: 6px"
          />
          <i
            v-else
            class="fas fa-paper-plane"
          />
        </div>
      </div>
    </div>

    <BModal
      v-model="form"
      hide-footer
      hide-header
      size="lg"
    >
      <ReportSendSettingsForm
        :subscription="edited"
        @close="form = false"
        @updated="subscriptions = $event"
      />
    </BModal>
  </div>
</template>

<script>
import CalendarDropdown from '@/components/shop/report/CalendarDropdown';
import ReportSendSettingsForm from '@/components/shop/report/ReportSendSettingsForm';
import getFileName from '@/utils/file-name';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    modal: false,
    form: false,
    days: [],
    dates: null,
    subscriptions: [],
    pending: {},
    edited: null,
    sendPending: false,
    downloadPending: false,
  }),
  components: {
    ReportSendSettingsForm,
    CalendarDropdown,
  },
  computed: {
    ...mapGetters([
      'payee',
      'userGroups',
      'properties',
    ]),
    ...mapGetters('shop', [
      'ordersReportTypes',
    ]),
    parsedSubscriptions() {
      return this.subscriptions
        .map((x) => ({
          ...x,
          config: JSON.parse(x.config),
          reportType: this.ordersReportTypes.find((t) => t.type === x.type),
        }))
        .map((x) => ({
          ...x,
          groups: x.config.groups
            .map((code) => this.userGroups.find((y) => y.code === code)?.path || '?'),
        }))
        .sort((a, b) => (a.config?.name || '').localeCompare(b.config?.name || ''));
    },
  },
  methods: {
    ...mapActions('shop', [
      'getReportSubscriptions',
      'toggleReportSubscription',
    ]),
    downloadReport(id, send) {
      if (this.pending[id]) {
        return;
      }

      this.$set(this.pending, id, send ? 'email' : 'download');

      this.toggleReportSubscription({
        params: {
          id,
          query: {
            send,
            day: this.days[0] || moment().format('YYYY-MM-DD'),
          },
        },
      })
        .then(({ data, headers }) => {
          if (!send) {
            const name = getFileName(headers);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => {
          this.$set(this.pending, id, undefined);
        });
    },
    request() {
      this.getReportSubscriptions()
        .then(({ data }) => {
          this.subscriptions = data;
        });
    },
  },
  created() {
    this.request();
    this.$emit('page', 'reports');
  },
};
</script>

<style lang="scss" scoped>

.subscription-row {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  .report-type-icon {
    font-size: 1.2rem;
  }
}

.detail-icon {
  width: 30px;
  min-width: 30px;
  text-align: center;
}

.subscription-details {
  line-height: 1.1;
}

.secondary-icon.disabled {
  opacity: 0.5;
}
</style>
