<template>
  <div>
    <div ref="wrapper">
      <div class="font-weight-bold position-relative">
        <div class="mt-1">
          {{ $t('shop.addOrders') }}
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$emit('close')"
          >
            ×
          </button>
        </div>
      </div>
      <hr>

      <div class="d-flex w-100">
        <div class="w-50 px-2">
          <label class="mb-2">
            {{ $t('shop.form.selectDays') }}
          </label>
          <div style="max-width: 400px; margin: auto">
            <Calendar
              :available-days="availableDays"
              :selected-days="[]"
              :day-status="dayStatus"
              @dates="dates = $event"
              @highlighted="highlighted = $event"
            />
          </div>
        </div>

        <div class="w-50 px-2">
          <label class="mb-2">
            {{ $t('shop.form.selectPayer') }}
          </label>
          <PayersFilterDropdown
            v-if="ready"
            class="mb-1"
            :tooltip-parent="$refs.wrapper"
            @selected="selectedPayers = $event"
          />

          <hr class="mt-3">
          <CounterInput
            v-model="count"
          />
          <hr class="mt-3">

          <BTextarea
            v-model="comment"
            :placeholder="$t('shop.comment')"
          />

          <label class="mt-4 mb-3">
            {{ $t('shop.form.selectProductSet') }}
          </label>
          <div>
            <ProductSetDropdown
              :product-sets="productSets"
              :value.sync="selectedProductSet"
              :tooltip-parent="$refs.wrapper"
            />
          </div>
        </div>
      </div>

      <hr class="mt-3">
      <div
        v-if="error"
        class="text-danger text-center small mb-3"
      >
        {{ $t('error.type.Unknown') }}
      </div>
      <div class="d-flex">
        <div class="w-50 px-2">
          <button
            class="btn btn-block btn-sm btn-secondary"
            @click="$emit('close')"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="w-50 px-2">
          <button
            class="btn btn-block btn-sm btn-primary"
            :disabled="!valid || pending || ordersToAdd.length === 0"
            @click="submit"
          >
            {{ $t('general.add') }} ({{ numberOfOrders }})
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import CounterInput from '@/components/utils/CounterInput';
import Calendar from '@/modules/payer/components/shop/Calendar';
import { mapActions } from 'vuex';
import ProductSetDropdown from './ProductSetDropdown';

export default {
  data: () => ({
    ready: false,
    selectedPayers: [],
    offer: [],
    orders: [],
    highlighted: [],
    count: 1,
    selectedProductSet: null,
    dates: null,
    pending: false,
    error: false,
    comment: '',
  }),
  components: {
    PayersFilterDropdown,
    ProductSetDropdown,
    Calendar,
    CounterInput,
  },
  computed: {
    numberOfOrders() {
      return this.highlighted.length * this.selectedPayers.length * this.count;
    },
    productSets() {
      return this.offer.flatMap((x) => x.offer.productSets);
    },
    valid() {
      return this.selectedProductSet
        && this.highlighted.length > 0
        && this.selectedPayers.length > 0;
    },
    availableDays() {
      return this.offer
        .flatMap((x) => x.offer.calendar)
        .filter((x) => x.productSets.concat(x.unavailableProductSets)
          .some((p) => p.productSetId === this.selectedProductSet))
        .map((x) => x.day);
    },
    dayStatus() {
      const withNewOrders = this.ordersToAdd.reduce((acc, curr) => {
        if (!acc[curr.day]) {
          acc[curr.day] = {
            new: this.count,
          };
        } else {
          acc[curr.day].new += this.count;
        }
        return acc;
      }, {});

      const payerIds = this.selectedPayers.map((x) => x.id);

      return (this.orders?.orders || [])
        .filter((x) => payerIds.includes(x.payerId))
        .reduce((acc, curr) => {
          if (!acc[curr.day]) {
            acc[curr.day] = {
              waiting: 0,
              paid: 0,
            };
          }

          if (curr.isPaid) {
            acc[curr.day].paid += 1;
          } else {
            acc[curr.day].waiting += 1;
          }
          return acc;
        }, withNewOrders);
    },
    ordersToAdd() {
      return this.offer
        .flatMap((x) => x.offer.calendar
          .filter((y) => this.highlighted.includes(y.day))
          .map((y) => ({
            day: y.day,
            availabilityId: y.productSets.concat(y.unavailableProductSets)
              .find((z) => z.productSetId === this.selectedProductSet)
              ?.availabilityId,
            payerId: x.payerId,
            count: this.count,
            comment: this.comment,
          }))
          .filter((y) => y.availabilityId));
    },
  },
  watch: {
    selectedPayers() {
      this.requestOffer();
    },
    dates() {
      this.requestOffer();
      this.requestOrders();
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateOrders',
      'getOrders',
      'getPayerOffer',
    ]),
    submit() {
      this.pending = true;
      this.updateOrders({
        data: {
          addedItems: this.ordersToAdd,
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    requestOffer() {
      if (this.selectedPayers.length === 0 || !this.dates) {
        this.offer = [];
        return;
      }

      this.getPayerOffer({
        params: {
          query: {
            payerIds: this.selectedPayers.map((x) => x.id),
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data }) => {
          this.offer = data;
        });
    },
    requestOrders() {
      this.orders = [];
      this.getOrders({
        params: {
          query: {
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data }) => {
          this.orders = data;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
.product-set-row {
  padding: 2px 8px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid transparent;

  .product-icon {
    width: 25px;
    color: #9a9a9a;
  }

  &.selected {
    border: 2px solid $blue;
  }

  &:hover {
    background-color: #eee;
  }
}

.close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 30px;
}
</style>
